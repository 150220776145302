.contact-button {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}
.contact-button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.contact-button:hover a {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
