.WhatsappCard {
  text-align: center;
  margin-top: 10%;
  width: 390px;
  height: 454px;
  background: #07182e;
  position: relative;
  margin: 5% auto;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}
.WhatsappCard:hover:before {
  background-image: linear-gradient(180deg, rgb(81, 255, 0), purple);
  animation: rotBGimg 3.5s linear infinite;
}
.WhatsappCard .ContentWhatsapp {
  display: flex;
  flex-direction: column;
}
.WhatsApp {
  z-index: 2;
  color: rgb(81, 255, 0);
  font-size: 2rem;
}
.WhatsApp:hover {
  color: rgb(81, 255, 0);
  font-size: 2.5rem;
}
.WhatsappCard::before {
  content: "";
  position: absolute;
  width: 100px;
  background-image: linear-gradient(
    180deg,
    rgb(0, 183, 255),
    rgb(255, 48, 255)
  );
  height: 130%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.WhatsappCard::after {
  content: "";
  position: absolute;
  background: #606264;
  inset: 5px;
  border-radius: 15px;
}

.WhatsappCard social-link4 {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  /* width: 25%; */
  color: whitesmoke;
  font-size: 5rem;
  text-decoration: none;
  transition: 0.25s;
  border-radius: 50px;
}
.WhatsappCard svg {
  /* position: absolute; */
  /* display: flex; */
  width: 20%;
  /* height: 100%; */
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
  cursor: pointer;
}

.WhatsappCard .social-link4:hover {
  width: auto;
  background-color: #12a50b;
  animation: bounce_613 0.4s linear;
}

@keyframes bounce_613 {
  40% {
    transform: scale(1.4);
  }

  60% {
    transform: scale(0.8);
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
