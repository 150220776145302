.CareerPage i {
  color: #ef603c;
  font-size: 3rem;
}

.header {
  filter: blur(2px);
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.text {
  position: relative;
  background: none;
  top: -35vh;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 80%;
  text-align: center;
  padding: 20px;
}
.text h1 {
  margin-bottom: 7%;
  color: #ef603c;
  font-size: 48px;
  text-align: center;
}
.exprience {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-items: self-end;
  justify-content: space-between;
}
.exprience h3 {
  margin-top: 6.5%;
}
.exprience h6 {
  color: #444444;
  font-size: 12px;
  opacity: 0.7;
}
.jobs {
  display: flex;
  text-align: flex-end;
  align-items: flex-end;
  /* flex-direction: column; */
  justify-content: space-evenly;
  margin-top: 18%;
  width: 100%;
  background-color: #f7f6f6;
}
.join-btn {
  position: relative;
  width: 143px;
  height: 48px;
  left: 0%;
  color: #f3f3f3;
  margin-top: 5%;
  background: #ef6603;
  border-radius: 10px;
  border: none;
}
.join-btn:hover {
  color: #ef6603;
  background-color: #f3f3f3;
}
.content {
  padding-left: 5%;
}

.content h2 {
  color: #ef603c;
  font-size: 32px;
  margin-top: 50px;
}
.contont {
  position: relative;
  height: 26px;
  top: -300px;
  text-align: center;
  margin: 2%;
}
.contont h2 {
  font-family: "Poppins";
  font-style: normal;
  color: #ef6603;
  margin-bottom: 2%;
}
.contont h4 {
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 26px;
  margin-bottom: 2%;
}
.contont p {
  color: #444444;
  font-family: "Poppins";
  font-style: normal;
}
.content p {
  font-size: 16px;
  width: 70%;
  color: #444444;
  text-align: justify;
}
.joinus {
  position: relative;
  height: 26px;
  left: 0;
  width: 100%;
  text-align: center;
}
.joinus h4 {
  margin-bottom: 2%;
}
.joinus h2 {
  margin-bottom: 2%;
}

.context {
  /* display: flex; */
  width: 100%;
  justify-content: space-around;
}
.content a {
  text-decoration: none;
  color: #444;
}

/* media queries */
@media screen and (max-width: 1200px) {
  .content {
    width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .header {
    height: 500px;
  }

  .header h1 {
    font-size: 36px;
  }

  .content {
    margin-top: 30px;
  }

  .content h2 {
    font-size: 28px;
  }

  .content li {
    font-size: 22px;
  }

  .content p {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 400px;
  }

  .header h1 {
    font-size: 28px;
  }

  .content {
    margin-top: 20px;
  }

  .content h2 {
    font-size: 24px;
  }

  .content li {
    font-size: 20px;
  }

  .content p {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .header {
    height: 300px;
  }

  .header h1 {
    font-size: 24px;
  }

  .content {
    margin-top: 15px;
  }

  .content h2 {
    font-size: 20px;
  }

  .content li {
    font-size: 18px;
  }

  .content p {
    font-size: 14px;
  }
}
