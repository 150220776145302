#blog {
  margin-top: 7%;
  margin-bottom: 2%;
}
#blog img {
  overflow-clip-margin: content-box;
  overflow: clip;
  vertical-align: middle;
  border-radius: 20px;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}
#blog h6 {
  text-align: right;
  opacity: 0.5;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #ef6603;
}

#blog .page-counter {
  display: flex;
  justify-content: space-between;
}
#blog .btn {
  border: 1px solid #ef6603;
  border-radius: 50px;
  color: #444;
  display: inline-block;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 10px;
  text-transform: uppercase;
  transition: 0.5s;
}
#blog Link,
button {
  text-decoration: none;
}
#blog .btn:hover {
  background: #ef6603;
  color: #fff;
}
#blog .title {
  padding-bottom: 40px;
}
#blog .title h2 {
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#blog .title h2:after {
  background: #fd9042;
  content: "";
  display: inline-block;
  height: 1px;
  margin: 4px 10px;
  width: 120px;
}
.title p {
  color: #444;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 5px;
  padding: 0;
  text-transform: uppercase;
  word-spacing: 5px;
}
.read-more {
  height: 16px;
  width: 136px;
  left: 90px;
  top: 885px;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.035em;
  text-align: left;
  color: #ef6603;
  margin-bottom: 10%;
  text-decoration: none;
}
.read-more:hover {
  color: #ef6603;
}
/* uxui */

#uxui .freespace {
  margin-bottom: 5%;
}
#uxui img {
  position: relative;
  left: 90px;
  top: 0;
  box-sizing: border-box;
  height: 512px;
  width: 60%;
  border-radius: 20px;
  margin-left: 2%;
}
#uxui input {
  /* position: absolute; */
  left: 85%;
  top: 10%;
  box-sizing: border-box;
  width: 180px;
  height: 40px;
  border: 1px solid rgba(68, 68, 68, 0.38);
  border-radius: 5px;
}
#uxui button {
  /* position: absolute; */
  /* left: 1073px;
  top: 294px; */
  width: 86px;
  height: 40px;
  left: 95%;
  top: 10%;
  background: #ef6603;
  border-radius: 5px;
}
#uxui .headItem {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  #uxui .headItem {
    flex-direction: column;
    align-items: center;
  }
}

#uxui .ul {
  /* position: absolute;
  left: 84%;
  top: 20%; */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
#uxui .ul li {
  /* list-style: decimal; */
  font-size: 16px;
  font-weight: 550;
  margin-top: 2.5%;
}
#uxui h3 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  color: #444;
}
#uxui h3:hover {
  color: #ef6603;
  transition: all 0.5s ease;
}
#uxui h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vw; /* relative font size based on viewport width */
  line-height: 1.6; /* relative line height */
  color: #ef6603;
  /* margin-left: 9.5%; */
  /* position: relative; */
  /* width: 9%;  relative width based on parent container */
  /* height: auto; allow height to adjust based on content */
  /*left: 10%;  relative horizontal position based on parent container */
  /*top: 40%;  relati */
}

#uxui .content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 985px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .content {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    margin-left: 0;
    margin-right: 0;
  }
}

#uxui .date {
  color: #ef6603;
}
#uxui .line {
  margin-top: 10px;
  margin-right: 1%;
  width: 69px;
  height: 2px;
  border: 1px solid #ef6603;
}
#uxui .topic {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

#uxui .data-st {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}
