.container-service li {
  margin-bottom: 2%;
  background: #f3f3f3;
  border-radius: 5px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  color: #444444;
}

.container-service {
  display: flex;
  flex-wrap: wrap;
}

.Item {
  width: 110%; /* Full width by default */
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Content ul {
  margin: 0;
  padding-right: 20%; /* Adjust the padding as needed */
}

.Content h3 {
  padding-left: 7%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.Item img {
  padding-left: 7%;
  margin-bottom: 2%;
  margin-top: 2%;
}

/* Responsive styles */
@media (min-width: 768px) {
  .Item {
    max-width: calc(100% / 2);
  }
}

@media (min-width: 992px) {
  .Item {
    max-width: calc(100% / 2);
  }
}

@media (min-width: 1200px) {
  .Item {
    max-width: calc(100% / 3);
  }
}

/* Additional media query for smaller screens */
@media (max-width: 767px) {
  .Item {
    max-width: calc(100% / 2);
  }
}

/* Additional media query for mobile screens */
@media (max-width: 480px) {
  .Item {
    max-width: 100%;
  }
}
