/* ContactForm.css */

/* Styling for the status message */
.status-message {
  display: block;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

/* Styling for the success status */
.status-message.success {
  background-color: #4caf50; /* Green */
}

/* Styling for the failed status */
.status-message.failed {
  background-color: #f44336; /* Red */
}
