.Max-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .Max-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .Max-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .Max-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .Max-container {
    max-width: 1140px;
  }
}
.services .icon-box {
  background: #fff;
  border-radius: 60%;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
  /* height: 255px; */
  /* margin-left: 2%; */
  /* overflow: hidden; */
  padding: 30px;
  position: relative;
  text-align: center;
  transition: all 0.4s ease-in-out;
  width: 343px;
}
.services .icon-box:hover {
  box-shadow: inset 3px 3px 6px 0 #ccdbe8,
    inset -3px -3px 6px 1px hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
}
.services .icon {
  left: 10px;
  position: absolute;
  top: calc(50% - 18px);
}
.the-icons {
  color: #ef6603;
  font-size: 5rem;
}
.services .icon i {
  font-size: 50px;
  line-height: 1;
  transition: 0.5s;
}
.services .title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 2%;
  padding-top: 2%;
  width: 100%;
}
.services .title a {
  color: #2a2c39;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}
.services .title a:hover {
  color: #ef6603;
}
.description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  margin-left: 40px;
}
