.blog-page {
  margin-top: 8%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.ad {
  width: 20%;
  background-color: #ccc;
  padding: 20px;
  box-sizing: border-box;
}
.bnt {
  font-family: "poppins";
  width: 86px;
  height: 40px;
  background: #ef6603;
  border-radius: 5px;
  border: none;
  color: #fff;
}
.left-section {
  text-align: left;
  width: 100%;
  max-width: 200px;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar input {
  width: 70%;
  margin-right: 10px;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid rgba(68, 68, 68, 0.38);
  border-radius: 5px;
}

.blog-list {
  list-style-type: square;
  margin: 0;
  padding: 0;
}

.blog-list li {
  list-style-type: square;
  color: #ef6603;
  list-style-position: outside;
  list-style-image: none;
  margin-bottom: 10px;
}

.middle-section {
  width: 80%;
  flex-basis: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.middle-section h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vw; /* relative font size based on viewport width */
  line-height: 1.6; /* relative line height */
  color: #ef6603;
}
.middle-section h3,
h4 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  color: #444;
}
.date {
  font-size: 1.5rem;
  color: #ef6603;
}
.line {
  margin-top: 1.2%;
  margin-right: 0.5%;
  width: 69px;
  height: 2px;
  border: 1px solid #ef6603;
}
.middle-section h3:hover {
  color: #ef6603;
  transition: all 0.5s ease;
}
.middle-section p {
  text-align: justify;
}
.middle-section li {
  list-style-type: disc;
  color: #ef6603;
}
.img {
  box-sizing: border-box;
  height: 412px;
  width: 800px;
  border-radius: 20px;
  /* margin-left: -2%; */
}
.right-section {
  width: 100%;
  max-width: 350px;
  flex-basis: 100%;
}
.right-section a {
  text-decoration: none;
  color: #444;
}
.page-list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-list li {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .blog-page {
    flex-wrap: nowrap;
  }
  .left-section {
    flex-basis: auto;
  }
  .middle-section {
    flex-basis: auto;
  }
  .right-section {
    flex-basis: auto;
  }
}

.page-contuer {
  margin-top: 5%;
}
.bttn {
  display: block;
  border: none;
  background-color: transparent;
}
.bnnt {
  display: block;
  background-color: #ef6603;
  border: none;
  border-radius: 50%;
  color: #fff;
}
#error404 {
  text-align: center;
  margin-top: 5%;
  height: 48vh;
}
#error404 button {
  width: 140px;
  height: 60px;
  border-radius: 10px;
  color: #fff;
  border: none;
  background-color: #ef6603;

  transition: background 1.5s ease-out;
}
#error404 button:hover {
  background: linear-gradient(to top left, #ef6603, #f8c7a5, #ef6604);
  transition: background 0.5s ease-in-out;
}
#error404 i {
  font-size: 2rem;
}
#error404 h1 {
  font-size: 4rem;
  text-align: center;
  color: #444;
  font-weight: 600;
  font-family: "poppines";
}
#error404 h1:hover {
  color: #ef6603;
}
