#About {
  box-sizing: border-box;
  margin-top: 3%;
}

/* =================== top section ================================ */
.parallel {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.text-about {
  width: 80%;
  height: 0%;
}
.text-about h3 {
  font-weight: 600;
  color: #ef6603;
}
.tasveer {
  text-align: right;
  width: 50%;
  /* display: none; */
  /* height: 100vh;
   */
}
.tasveer1 {
  margin-top: 1%;
  position: relative;
}

.center {
  text-align: center;
}
@media (max-width: 1199px) {
  .tasveer {
    display: none;
  }
}

/*============================================= Counter  ==========================================*/
.counter-section {
  margin: 3%;
}

.counter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Data {
  box-sizing: border-box;
  margin-top: 5%;

  width: 219px;
  height: 219px;
  background: #ffffff;
  border: 1px solid rgba(68, 68, 68, 0.5);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Data h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 32px;
  color: #444444;
  margin-top: 18px;
}

.Data h4 {
  font-family: "Poppins";
  font-size: 20px;
  color: #ef6603;
}

.Data p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

@media (max-width: 600px) {
  .counter-section {
    flex-direction: column;
  }
}

/* =============================================== Trusted By =============================================== */
.Trusted {
  margin-top: 2%;
  margin-bottom: 2%;
  height: 250px;
  /* background: #f3f3f3; */
}
.Trusted h3 {
  margin: 3%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #444444;
}
.copm-logs {
  margin: 2%;
  display: flex;
  justify-content: space-between;
}
.Trusted .imexg {
  height: 52px;
  width: 238px;
  border-radius: 0px;
}
.comp-logo {
  width: 250px;
}
/*======================================== misson section======================================== */
.OurMission {
  margin: 3%;
  display: flex;
  flex-direction: column;
}
.ceo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; /* Align elements to the right */
}

.ceo-talk {
  width: 100%;
}

.ceo-talk h4 {
  text-align: center;
  font-size: 32px;
  margin-left: 3%;
}

.ceo-talk p {
  font-size: 20px;
  margin-bottom: 2rem;
}

.ceo-img {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  /* text-align: right; */
  min-width: 10%;
  order: -1; /* Change the order to push it to the right */
}
.ceo-img img {
  height: 490px;
  border-radius: 15px;
}

.ceo-name {
  position: relative;
  /* text-align: right; */
  /* Align CEO's name to the right */
  width: 240px;
  height: 81px;
  background-color: #f3f3f3;
  border-radius: 10px;
}
.ceo-name h5 {
  text-align: center;
}
.ceo-name h3 {
  text-align: center;
  font-size: 20px;
  /* margin-bottom: 2%; */
  margin-left: 3%;
  color: #ef6603;
}

/* Media Queries */
@media screen and (min-width: 1200px) {
  /* Styles for screens larger than 1200px */
  .ceo-container {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
  }

  .ceo-talk {
    width: 50%;
  }

  .ceo-img {
    /* display: inline-block; */
    width: 45%;
    max-width: 500px;
    align-self: flex-start;
  }

  .ceo-name {
    order: 1;
    position: static;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1199px) {
  /* Styles for screens smaller than 1200px */
  .ceo-container {
    flex-direction: column;
  }

  .ceo-talk {
    width: 100%;
  }

  .ceo-img {
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
  }

  .ceo-name {
    margin-top: 1rem;
  }
}
/* ===================================== OUR STORY ======================================== */
.ourStory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3%;
}

.story-img {
  flex-basis: 40%; /* Adjust the width of the image container */
}

.story {
  flex-basis: 50%; /* Adjust the width of the text container */
}

.story h2 {
  font-size: 32px;
  font-weight: 600;
}

.story h4 {
  font-size: 24px;
  font-weight: 500;
}

.story p {
  font-size: 20px;
  margin-top: 1rem;
}

.readmore-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  text-decoration: underline;
}

.readmore-btn:focus {
  outline: none;
}

/* Show more/less functionality */
.story .show-more {
  display: none;
}

.story.show-full .show-more {
  display: inline;
}

.story.show-full .show-less {
  display: none;
}
/* Media Queries */
@media screen and (min-width: 1200px) {
  /* Styles for screens larger than 1200px */

  .story {
    width: 50%;
  }

  .story-img {
    width: 45%;
    max-width: 500px;
    align-self: flex-start;
  }
}

@media screen and (max-width: 1199px) {
  /* Styles for screens smaller than 1200px */

  .ourStory {
    flex-direction: column;
    align-items: center;
  }

  .story-img {
    margin-bottom: 1.5rem;
  }
}
/* =========================== OUR TEAM ========================================= */
.team-section {
  text-align: center;
  padding: 2rem;
  background-color: #f3f3f3;
}
.team-section h2 {
  color: #ef6603;
  font-weight: 600;
}
.team-section h3 {
  font-weight: 500;
  font-size: 32px;
  color: #000000;
}
.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0px 0px;
}
.member-info {
  width: 100%;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
}
.member-info h4 {
  margin-top: 0.5rem;
  font-size: 18px;
  font-weight: 600;
}

.member-info p {
  margin-top: 0.25rem;
  font-size: 14px;
  color: #888;
}

/* ================================ Quote ============================================== */
.quote-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4%;
  margin-bottom: -4%;
  height: auto;
}

.quote-content {
  flex: content;
}

.quote-content p {
  font-size: 18px;
  /* margin-top: 4%; */
  line-height: 1.5;
}

.company-logo {
  margin-left: 20px;
}

.company-logo img {
  max-width: 30%;
}
@media (max-width: 767px) {
  .quote-section {
    flex-direction: column;
    align-items: center;
  }

  .company-logo {
    margin-right: 0;
    /* margin-bottom: 20px; */
  }
}

/* ========================================== TEAM ======================================== */
.img-zaheer {
  max-width: 250px;
}
