/* new lex */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  color: #333;
  height: 80px;
  position: fixed;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.logo-box {
  min-width: 150px;
  min-height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../logo512.png);
}
.menu-icon {
  cursor: pointer;
  display: none;
  padding: 10px;
  background-color: rgba(239, 102, 3, 0.1);
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}
.menu-icon:hover {
  background-color: rgba(239, 102, 3, 1);
}
.navbar-menu {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar a,
.navbar a:focus {
  /* align-items: center; */
  border-radius: 50px;
  color: #444;
  /* display: flex; */
  font-size: 18px;
  /* justify-content: space-between; */
  margin-left: 5px;
  padding: 5px 15px 7px;
  transition: 0.3s;
  white-space: nowrap;
}
/* .navbar li {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
} */
.navbar ul {
  /* align-items: center; */
  /* display: flex; */
  /* list-style: none; */
  margin: 0;
  padding: 0;
}
.navbar a i,
.navbar a:focus i {
  color: #ef6603;
  font-size: 12px;
  line-height: 0;
}
.navbar .active,
.navbar .active:focus,
.navbar a :hover,
.navbar li:hover > a {
  background: #ef6603;
  color: #fff;
}

.menu-icon span {
  display: block;
  height: 2px;
  width: 20px;
  background-color: #fff;
  margin: 5px 0;
  transition: transform 0.2s ease-in-out;
}

.menu-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media screen and (max-width: 1200px) {
  .navbar-menu {
    display: none;
    position: absolute;
    text-align: center;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #f3f3f3;
    padding: 20px;
  }

  .navbar-item {
    margin-bottom: 10px;
  }

  .navbar-link {
    font-size: 1.2rem;
    text-decoration: none;
    color: #fff;
    transition: color 0.2s ease-in-out;
  }

  .navbar-link:hover {
    color: #f00;
  }

  .menu-icon {
    display: block;
  }

  .navbar-menu.open {
    display: block;
  }
}
