.section-title {
  /* display: flex;
  justify-content: space-between; */
  max-width: 1440px;
  margin: 0 auto;
}

.testimonial {
  /* border: 1px solid transparent; */
  text-align: center;
  /* padding: 20px; */
}

.testimonial-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

.testimonial-text {
  margin: 0 auto;
  font-family: Lato;
  text-align: justify;
  width: 770px;
  height: auto;
  margin-top: 10px;
  font-size: 24px;
  line-height: 1.5;
  background: rgba(255, 241, 229, 1);
  border-radius: 10px;
}

.testimonial-rating {
  margin-top: 10px;
}

.star {
  color: #ccc;
  font-size: 18px;
}

.star.filled {
  color: gold;
}
/* TestimonialSlider.css */
.testimonial-slider {
  /* max-width: 500px; */
  margin: 0 auto;
  padding: 20px;
}

.testimonial2 {
  text-align: center;
  padding: 20px;
  /* border: 1px solid #ccc; */
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.custom-dot-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 177px;
  height: 17px;
  top: 5073px;
  left: 631px;
  border: 1px;
  background-color: #ff6347;
}
/* Styles for individual dots */
.carousel .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #ff6347; /* Default dot color */
  transition: background-color 0.3s ease;
}

/* Add colors to specific dots using class names */
.carousel .dot.custom-dot-0 {
  background-color: #ff6347; /* Red */
}

.carousel .dot.custom-dot-1 {
  background-color: #32cd32; /* Green */
}

.carousel .dot.custom-dot-2 {
  background-color: #1e90ff; /* Blue */
}
